import request from 'utils/request';

const api = {
  OVERALL_LIST: '/api/prod/list',
  GET_PRODS: '/api/prod/selectProds',
  UPDATE_APPNAME: '/api/prod/updateAppName',
  SELECT_APPS: '/api/prod/selectApps',
};

export function getProdList(query) {
  return request({
    url: api.OVERALL_LIST,
    method: 'get',
    params: query,
  });
}
export function selectProds(query) {
  return request({
    url: api.GET_PRODS,
    method: 'get',
    params: query,
  });
}

export function selectApps(query) {
  return request({
    url: api.SELECT_APPS,
    method: 'get',
    params: query,
  });
}

export function updateAppName(data) {
  return request({
    url: api.UPDATE_APPNAME,
    method: 'post',
    data,
  });
}
