<template>
  <div class="productOverall">
    <!-- <div class="main-Title bgff">
      <h2>Product Overall</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :inline="true" :model="filter" label-width="90px" size="mini">
          <el-form-item label="Package Name" class="mb0">
            <el-select v-model="filter.prod" clearable filterable allow-create>
              <el-option
                v-for="item in pkgList"
                :key="item"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="app Name" class="mb0">
            <el-select v-model="filter.name" clearable filterable allow-create>
              <el-option
                v-for="item in appList"
                :key="item"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="PM" class="mb0">
            <el-select
              v-model="filter.productManager"
              filterable
              clearable
              placeholder="Please select"
              class="w100"
            >
              <el-option
                v-for="item in option.manager"
                :key="item.username"
                :label="item.username"
                :value="item.username"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="pmType" class="mb0">
            <el-select
              v-model="filter.pmType"
              placeholder="Please Select pm type"
              clearable
              size="mini"
              style="width: 100%"
            >
              <el-option
                v-for="item in option.pmTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="important" class="mb0">
            <el-select
              v-model="filter.important"
              placeholder="Please Select app import"
              clearable
              size="mini"
              style="width: 100%"
            >
              <el-option
                v-for="item in option.importants"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="mb0">
            <el-button :loading="loading.list" type="primary" @click="searchClick(1)"
              >Search</el-button
            >
            <el-button type="info" @click="downloadFie()">download</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table :data="prodList" border stripe class="w100" size="mini" v-loading="loading.list">
          <el-table-column label="Prod Id" prop="id"></el-table-column>
          <el-table-column label="Package Name">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="prodDetail(scope.row)">{{ scope.row.prod }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="Aff Black Status">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.affBlacklistSwitch == true" type="success">已拉黑</el-tag>
              <el-tag v-else type="danger">未拉黑</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column label="app Name" prop="name" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.name }}
              <i
                class="el-icon-edit"
                v-if="!(accountRoles.indexOf('Ad Operation') > -1 && userName != 'eleanor')"
                @click="handleAppNameDialog(scope.row)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="PM" prop="productManager"></el-table-column>
          <el-table-column label="Action" align="center" fixed="right" width="auto">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                :disabled="accountRoles.indexOf('Ad Operation') > -1 && userName != 'eleanor'"
                @click="handleAppNameDialog(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--编辑appName模态框-->
        <el-dialog title="编辑" :visible.sync="dialog.visible" width="50%" height="100%">
          <el-form ref="productForm" :model="productObj" :rules="rules" label-width="120px">
            <el-form-item label="productName" prop="name">
              <el-input v-model="productObj.name" placeholder="add appName here"></el-input>
            </el-form-item>
            <el-form-item label="PM" label-width="90px" class="mb0">
              <el-card shadow="never" class="mb10">
                <el-row
                  :gutter="10"
                  v-for="(item, index) in productObj.productManagerMaps"
                  :key="index"
                  :class="productObj.productManagerMaps.length > 1 ? 'mb5' : 'mb0'"
                >
                  <el-col :span="7">
                    <el-form-item label="" label-width="0px" class="mb0">
                      <el-select
                        v-model="item.pm"
                        filterable
                        placeholder="Please select"
                        class="w100"
                      >
                        <el-option
                          v-for="item in option.manager"
                          :key="item.username"
                          :label="item.username"
                          :value="item.username"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label="" label-width="0px" class="mb0">
                      <el-select
                        v-model="item.type"
                        placeholder="Please Select pm type"
                        size="mini"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in option.pmTypes"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label="" label-width="0px" class="mb0">
                      <el-select
                        v-model="item.appType"
                        placeholder="Please Select app important"
                        size="mini"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in option.importants"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="" label-width="0px" class="mb0">
                      <el-button type="primary" size="mini" v-if="index == 0" @click="dataFilterAdd"
                        >add</el-button
                      >
                      <el-button
                        type="danger"
                        size="mini"
                        v-if="index !== 0"
                        @click="dataFilterDel(index)"
                        >delete</el-button
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-form-item>
            <el-form-item>
              <el-button @click="dialog.visible = false">取 消</el-button>
              <el-button
                type="primary"
                @click="submitForm('productForm')"
                :loading="loading.submitBtn"
                >确 定</el-button
              >
            </el-form-item>
          </el-form>
        </el-dialog>

        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageSize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import ProductOverallCon from '../controllers/product/ProductOverall';

  export default {
    name: 'ProductOverall',
    ...ProductOverallCon,
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
